import { render, staticRenderFns } from "./adcarouselsmall.vue?vue&type=template&id=ca4bee7e&scoped=true&"
import script from "./adcarouselsmall.vue?vue&type=script&lang=js&"
export * from "./adcarouselsmall.vue?vue&type=script&lang=js&"
import style0 from "./adcarouselsmall.vue?vue&type=style&index=0&id=ca4bee7e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca4bee7e",
  null
  
)

export default component.exports