<template>

<div>

    <template v-if="format=='block'">
   
        <div  :style="{backgroundColor: backcolour}" style="text-align:center;padding-top:2px;border-radius:10px 10px 0px 0px;"><a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.country" target="_blank" ><span :class="'b' + row.Description"></span></a></div>
            <div  class="align-middle">
                        <div> 
                            <div class="qmediumboldfont " :style="{backgroundColor: backcolour, color: fontcolour}" style="text-align:center;border-bottom: 1px solid #00dce2;" v-html="row.BanTitle"></div>
                            <div class="qsupersmallfont " :style="{backgroundColor: backcolour, color: fontcolour}" style="border-radius:0px 0px 10px 10px; padding: 10px;height:90px;color:white" v-html="row.BanDesc"></div>
                        </div>
                        <div  style="position:relative; top: -30px; left:5px" ><begambleaware :tandcs="tandcs"></begambleaware></div>
                        <div class="grid-x">
                        <div class="cell auto"></div>
                        <div class="cell shrink" style="position:relative; top: -25px">
                            <actionbutton :bookmaker="row.Description"></actionbutton>
                        </div>
                        <div class="cell auto"></div>
            </div>
        </div> 
      
    </template>

    <template v-if="format=='smallpromo'">

        <div class="grid-x" style="padding: 8px;height:90px" :style="{backgroundColor: backcolour}">
            <div style="height:62px">
            <div class="cell shrink"><a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.country" target="_blank" ><div class="qnewplayers qsupersmallfont">{{langConv('New Customer')}}</div><div :class="'m' + row.Description"></div> </a></div>
           
            </div>
            <div class="cell auto">
                <a :href="'/bookmakerloader.html?bkm=' + row.Description + '&country=' + $store.state.country" target="_blank" >
                <div class="grid-y">
                    <div class="grid-x">
                        
                    </div>
                    <div class="grid-x" >
                        <div class="cell small-9 qmediumboldfont" :style="{backgroundColor: backcolour, color: fontcolour}"  v-html="row.BanTitle"></div>
                        
                        
                    </div>
                    <div class="grid-x grid-padding-x" >
                        <div class="cell small-9 qsupersmallfont" :style="{backgroundColor: backcolour, color: fontcolour}" style="color:white;margin-top:4px" v-html="row.BanDesc"></div>
                        <div class="cell small-3" style="padding-top:3px"><actionbutton :bookmaker="row.Description" buttontext="Go" ></actionbutton></div>
                    </div>
                </div>
            </a>
            </div>
                    
        </div> 

            <!-- <div class="qsmallfont"> foot notes</div> -->
        
    
    </template>




</div>

</template>

<script>

import actionbutton from '/src/components/components/actionbutton.vue'
import begambleaware from '/src/components/adverts/begambleaware.vue'
    
    export default {
        name: "singleadverts", 
        data: function data() {
                return {
                    fontcolour: 'white'
                }
         },
        components: {
            actionbutton,
            begambleaware
        },

        mounted: function mounted() {

            if ( this.backcolour.toLowerCase() == 'white') {this.fontcolour = 'black'} else {this.fontcolour = 'white'}
            

        },
    
        props: {
            row: Object,
            backcolour: { type: String, default: 'white'},
            tandcs: {type: Boolean, default: true},
            format: {type: String, default: 'block'},


        }
    }





</script>



    





    