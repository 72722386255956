<template>


<div class="cell medium-10 small-12" >

    <div id="oddstable" class="grid-container qsmallfontplus1" >

        <div class="grid-x grid-padding-x" style="min-height:100vh" >

            <div class="cell small-12 medium-12 large-8" style="border-right:1px solid #E6E7E9;">

                <div v-for="(row,i) in infotabledata" :key="i"  >

                  
                    <div v-if="row.nodata != null">
                        <nodata></nodata>
                    </div>

                    <div v-if="row.error != null">
                        <div class="parent">
                            <div class="child">
                                    <p style="color:gray;line-height:100px">Something went wrong getting your data</p>
                                    <p style="color:black;line-height:100px">{{row.desc}}</p>
                               
                            </div>
                        </div>
                    </div> 

                    
                </div>

            <div :class="{loader: !oddsloaded}"></div>
            <div style="padding-top:10px" v-if="oddsloaded &&  infotabledata.length == 0">
             
            <breadcrumb></breadcrumb>

              <div style="position: relative;height:40px;margin-top:-16px" >

                        <div class="qlargecircularimage show-for-medium" style="position:absolute;left:0px;margin-top:10px"><star id="star1" :cmpid="odds[0].h.t1id" :cmpdesc="players[0]" tooltipfacinglright=true></star><kit id="kit1" :country="odds[0].h.t1country" :sport="sport" :vmagnify="5" :hmagnify="5" :teamname="players[0] " ></kit></div>
                       
                        <div class="qlargecircularimage show-for-medium" style="position:absolute;right:0px;margin-top:10px" ><kit id="kit2" :country="odds[0].h.t2country" :sport="sport" :vmagnify="5" :hmagnify="5" :teamname="players[1]" ></kit><star id="star2" :cmpid="odds[0].h.t2id" :cmpdesc="players[1]" tooltipfacinglright=false ></star></div> 
                       
                        <div class="show-for-small-only" style="padding-top:5px;" >

                            <div><flickthrough  :ftdata="ogldata" :fontsize="14"  v-if="ogldataloaded" @eventchange="changetpo"></flickthrough></div>
                            <h2 class="qsubheader qsmallfontplus">{{langConv('Start Time') + ':' + $todayTomorrow(odds[0].h.sd,$dateformattype.daymonth) }} @ {{$todayTomorrow(odds[0].h.sd,$dateformattype.timeonly) }}</h2>
                            
                        </div>

                        <div class="show-for-medium" style="padding-top:5px;padding-left:15px;padding-right:15px" >

                            <div><flickthrough  :ftdata="ogldata" :fontsize="16"  v-if="ogldataloaded" @eventchange="changetpo"></flickthrough></div>
                            <h2 class="qsubheader qsmallfontplus1">{{langConv('Start Time') + ': ' + $todayTomorrow(odds[0].h.sd,$dateformattype.daymonth) }} @ {{$todayTomorrow(odds[0].h.sd,$dateformattype.timeonly) }}</h2>
                            
                        </div>

                    </div>
                    <div class="grid-x" style="padding: 50px 0px 4px 0px">
                        <div class="cell auto qlegendfont oddsbest">Best odds</div>
                        <div class="cell auto qlegendfont">Odds rising<span class="oddsout"></span></div>
                        <div class="cell auto qlegendfont ">Odds falling<span class="oddsin"></span></div>
                        <div class="cell small-5"><dropdown :ocdefaultvalue="betinfo[0]" @change_bettype="changeBettype" ocname="OtherTPOBetTypes" :ocid="tpoid" :ocnoitems="10"></dropdown></div>
                    </div>


                    <!--header-->
                    <div class="grid-x text-center qsmallfont qhighlight" style="padding: 5px">

                         <div @click="sortRefresh('bn')" class="cell shrink show-for-medium " style="color:#f3f3f3">A<span style="width:10px">&nbsp;</span><span style="width:'10px'">&nbsp;</span></div>
                        <div @click="sortRefresh('bn')" class="cell small-2 qlink" >{{langConv(Bookmaker)}}</div>
                        <div @mouseover="mouseQuadrant"    @click="sortRefresh('newcustomer')" :class="{tooltip: hints}" class="cell small-1 qnewplayers" >New
                            <span class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}">{{langConv('These promotions are only available to those who are, for the first time, opening an account')}}</span> 
                        </div>
                        <div @mouseover="mouseQuadrant"    @click="sortRefresh('allpunters')" :class="{tooltip: hints}" class="cell small-1 qallplayers">All
                            <span class="tooltiptext" :class="{'tooltip-right': $store.state.rightside}">{{langConv('These promotions are available to all customers and are very likely to be specific to this event (or competition)')}}</span> 
                        </div>

                        <div @click="sortRefresh('o1')" class="cell auto qlink">{{ odds[0].h.h1 }}</div>
                        <div @click="sortRefresh('o2')" class="cell auto qlink" v-if="draw">{{ odds[0].h.h2 }}</div>
                        <div @click="sortRefresh('o3')" class="cell auto qlink">{{ odds[0].h.h3 }}</div>

                    </div>

                    <div class="grid-x qsmallfontplus text-center" style="border-bottom: 1px #E6E7E9 solid;"  v-for="(row,i) in sortedOdds" :key="i">
                       


                        <div  :class="trafficLight(Math.abs(row.nu))" class="cell shrink show-for-medium " style="height:20px;width:20px;font-size:12px" ><Span style="cursor:pointer" :title="row.orgtypedesc + ' - whose odds are ' +  Math.abs(row.nu)  + ' sec(s) old'   ">{{ row.orgtype }}</Span>
                           
                                <!-- <span class="tooltiptext" >{{ row.orgtypedesc }}<br/> {{ Math.abs(row.nu) }} secs old</span> -->
                            
                        </div>
                        <div class="cell small-2" style="padding-top:1px"><a :href="'/bookmakerloader.html?bkm=' + row.bn + '&country=' + $store.state.countryname" target="_blank"><span v-bind:class="'b' + row.bn" ></span></a></div>
                        <!-- the below needs turning into a component-->
                        <div class="cell small-1">

                            <div class="dropdown" style="padding-top:3px" v-if="row.newcustomer > 0">
                                <span class="qnewplayers" style="margin-left:2px" @mouseover="promo(row.bnid.toString() + ',' + odds[0].h.compid.toString() + ',1,0',$event,'regular')" >{{ numbertoCurrency(row.newcustomer) }}</span>
                            </div>

                        </div>
                        <div class="cell small-1">
                            <div class="dropdown" style="padding-top:3px" v-if="row.promos> 0">

                                    <span class="qallplayers"  style="margin-left:2px;" @mouseover="promo(row.bnid.toString() + ',' + odds[0].h.compid.toString() + ',2,' + row.eventid.toString(),$event,'allpunters')">
                                        <img style="height:16px;width:16px" src="/assets/icons/all-inclusive.png"/>
                                    </span>

                            </div>
                        </div>


                        <div class="cell auto"  v-bind:class="[{ bestodds: oddsStyleBO(row.o1,odds[0].h.bo1), oddsout: oddsStyleOut(row.lo1d), oddsin: oddsStyleIn(row.lo1d), oddsstatic: oddsStyleStatic(row.lo1d)}]" v-html="$formatOddsTS(row.o1,row.bn)"></div>
                            <template v-if="draw">
                                <div class="cell auto"  v-bind:class="[{ bestodds: oddsStyleBO(row.o2,odds[0].h.bo2), oddsout: oddsStyleOut(row.lo2d), oddsin: oddsStyleIn(row.lo2d), oddsstatic: oddsStyleStatic(row.lo2d)}]" v-html="$formatOddsTS(row.o2,row.bn)"></div>
                            </template>
                        <div class="cell auto"  v-bind:class="[{ bestodds: oddsStyleBO(row.o3,odds[0].h.bo3), oddsout:oddsStyleOut(row.lo3d) , oddsin: oddsStyleIn(row.lo3d), oddsstatic: oddsStyleStatic(row.lo3d)}]" v-html="$formatOddsTS(row.o3,row.bn)"></div>

                    </div>

                </div>

            </div>

            <div class="cell auto" style="margin:5px;">
                <template v-if="tableloaded">

                    <h3 class="qmajorheader qmediumboldfont qalignleft qmarginbelow" >{{ compdesc }} {{langConv('Table')}}</h3> 
                    <div style="height:8px"></div>
                    <div class="cell auto ">

                        <div class="grid-x qsmallfont" v-for="(te,i) in league[0].l" :key="i">
                            <div class="cell small-1" v-if="i>0">{{i}}</div>
                            <div class="cell small-1" v-else>#</div>
                            <div class="cell auto " style="text-align: left;">{{te.name}}</div>
                            <div class="cell small-1" >{{te.pg}}</div>
                            <div class="cell small-1" style="padding:0px 5px 0px 5px" >{{te.gd}}</div>
                            <div class="cell small-1" style="padding:0px 5px 0px 5px">{{te.pts}}</div>
                        </div>

                    </div>
                </template>              

                <resultstable :eventid="Number(tpoid)" :header="true"></resultstable>

                <div>

                <h3 class="qmajorheader qmediumboldfont qalignleft">{{   langConv('New Customer Offers') }}</h3>

                <offers param1="New_Customers_Brief"></offers>

                </div>

            </div>

        </div>

        <div style="color:lightgray">{{langConv('Page last updated:') }}  + {{lastOddsUpdate}}</div>
    </div>


</div>
</template>

<script>

    //import bannerodds from '../components/adverts/bannerodds.vue';
    import breadcrumb from '../components/navigation/breadcrumb.vue';
    import dropdown from '../components/navigation/dropdown.vue';
    import offers from '../components/adverts/offers.vue';
    import axios from 'axios';
    import kit from '../components/image/kit.vue';
    import flickthrough from '../components/components/flickthrough.vue';
    import star from '/src/components/components/star.vue';
    import nodata from '/src/components/components/nodata.vue';
    import resultstable from '../components/components/results.vue';


    export default {
        name: "eventodds",
        data: function() {
            return {
               
                    // advertpop: [],
                    odds: [],
                    league: [],
                    banners: [],
                    results: [],
                    currentSort: 'orgtype',
                    currentSortDir: 'asc',
                    oddsOut: false,
                    oddsIn: false,
                    timer: '',
                    lastOddsUpdate: '',
                    oddUpdated: false,
                    advert: '',
                    statview: 'EOLeagueTable',
                    statid: 0,
                    resultsloaded: false,
                    tableloaded: false,
                    oddsloaded: false,
                    ogldata: [],
                    ogldataloaded: false,
                    tpo_id: this.tpoid,
                    players: [],
                    betinfo: this.bettype,
                    infotabledata: [],
                    draw: true,
    
                }
            },

            props: {
                sport: String,
                country: String,
                competition: String,
                competitors: String,
                bettype: String,
                tpoid: {Number, String}
                //, extrabetinfo: String
            },

            beforeCreate() {

                //if no ipaddress stored (couldnt retrieve country from API) or No License for country and no sportsbooks have been selected
                if ((this.$store.state.ipaddress.length == 0 || this.$store.state.regbody.length == 0 || this.$store.state.sb.length == 0)) {
                   
                   // console.log('sb len:' + this.$store.state.sb.length)

                  this.$modal.show('regulationarea');
                
                }

            },

            mounted() {


                this.players = this.splittoarray(this.removehyphens(this.competitors)," v ");

                this.betinfo = this.splittoarray(this.removehyphens(this.bettype),"~");

                //get othergames in league
                this.otherGamesinLeague();

                //first load of odds
                this.oddsTable();

                //then put refresh on timer
                this.timer = setInterval(this.oddsTable,10000) // odds refresh every 10 secs - should be configurable (settings page)

                this.resultsTable();
                this.leagueTable();


            },

            beforeDestroy() {
               clearInterval(this.timer);

           
            },

            components: {
                breadcrumb,
                dropdown,
                offers,
                kit,
                flickthrough,
                star,
                nodata,
                resultstable

            },

            asyncComputed: {

                ////turn odds data into an array so it can be sorted
                sortedOdds: function () {

                    if (!this.oddsloaded == true || this.odds[0].O == null) { return}

                    return this.odds[0].O.slice().sort((a, b) => {
                        let modifier = 1;
                        if (this.currentSortDir === 'desc') modifier = -1;
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;

                    });

                }

            },

            computed: {

                compdesc() {
                    return this.removehyphens(this.competition);
                },
                hints () {
                    return this.$store.state.showhints;
                }

            },

            methods: {

  

                changeBettype: function (val) {

                      //clear down array
                      this.betinfo = [];

                      var binfo
                      var btype

                      if (val.includes("(")) {
                        binfo = val.slice(val.lastIndexOf('(')+1,val.length-1);
                        btype  = val.slice(0,val.lastIndexOf('(')-1);
                      } else {
                        btype = val;
                        binfo = "";
                      }

                      this.betinfo.push(btype,binfo);

                      this.oddsTable();
                },


                changetpo: function (val) {

                    this.tpo_id = val.o;
                    this.players = this.splittoarray(this.removehyphens(val.d)," v ");
                    this.oddsTable();
                },

                otherGamesinLeague: function() {

                    this.ogldataloaded = false;
                    let params = '[{ "Name": "@noitems", "Value": 20},{ "Name": "@compdesc", "Value": "' + this.compdesc + '"},{ "Name": "@tpoid", "Value": ' + this.tpoid + '}]';
                    let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/RetrieveGeneric";
                    let spname = "OtherGamesInLeague_NS";

                    //get bet results for that bet
                    this.newgenericresultset(url, params,spname).then(response =>
                        {
                        
                          this.ogldata = response
                          this.ogldataloaded = true;
                        });

                },

                //method called for changing sort on a column
                sortRefresh: function (s) {
                    if (s === this.currentSort) {
                        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                    }
                this.currentSort = s;

            },


            //does not do anything at mo because of problems with tabs control 12062020

                changeStatView: function (newVal) {

                    if (this.statview == newVal.tab.id) {
                       return;
                    } else {
                        this.statview = newVal.tab.id;
                    }

                },



                //get data for odds table
                oddsTable: function () {

                  //  this.oddsloaded = false;

                    var _self = this

                    axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/OddsTable", {

                        params: {
                            eventid: this.tpo_id,
                            bettype: this.betinfo[0],
                            extrabetinfo: this.betinfo[1],
                            selectedbookmakers: JSON.stringify(this.$store.state.sb),
                            country: this.$store.state.countryname
                        }

                    }).then(response => {

                       this.infotabledata = [] //this array is for catching errors and issues

                        this.odds = JSON.parse(response.data.OddsTableResult)

                        //if there is only on row returned (a header) then return a mock error message


                        if(this.odds[0].O != undefined) {

                        console.log('oddsresponse - ')
                        //console.log(this.odds)
                        this.lastOddsUpdate = new Date()
                        this.anyDraw(); // check to see if any odds for outcome 2

                        console.log("update")

                    

                        } else {

                            this.infotabledata = JSON.parse('[{"error":"true","desc": "' + this.langConv('There are no odds for this event for your current settings') + '"}]');  
                           
                        }

                    }).catch(function(error){

                        var errmsg = ""

                        if (error.response.data) { errmsg = error.response.data; }

                        this.infotabledata = JSON.parse('[{"error":"true","desc": "' + errmsg + '"}]');

                        

                    }).finally(()=>{

                        this.oddsloaded = true

                    });


                    //clear the timer
                },

                //get data for league table
                leagueTable: function () {

                    //this.tableloaded = false;

                    axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/BasicLeagueTable", {

                        params: {
                            noitems: 99,
                            compdesc: this.compdesc
                        }

                    }).then(response => {

                        this.league = JSON.parse(response.data.BasicLeagueTableResult)
                        if (this.league[0].l.length > 1) { this.tableloaded = true; }

                    });
                },

                resultsTable: function () {

                    //this.resultsloaded = false

                    axios.get(process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/ResultsFromTPOID", {

                        params: {
                            noitems: 10,
                            tpoid: this.tpoid
                        }

                    }).then(response => {
                    this.results = JSON.parse(response.data);

                    if (this.results[0].r != undefined) { this.resultsloaded = true; }


                    });

                },

                trafficLight(val) {
                        //green less than 5 mins
                        if (val < 300) { return 'Go'}
                        //amber if 15mns or less
                        if (val < 900) { return 'Wait'}
                        //else red
                        return 'Stop'
                },


                oddsStyleBO: function(odd, bo) {

                    if (bo === odd) { return true;}

                },
                oddsStyleOut: function(mo) {

                    if (mo > 0) { return true } else {return false}

                },
                oddsStyleIn: function(mo) {

                    if (0 > mo) { return true } else {return false}
                },

                oddsStyleStatic: function(mo) {
                    if (mo == 0) { return true } else {return false}
                },


                anyDraw: function() {

                    for (var i=0; i < this.odds.length; i++) {
                        
                        let row = this.odds[i];
                        if (row.O[1].o2!=null) {
                            this.draw = true;
                            break;
                    
                        } else {
                            this.draw = false;
                        } 
                    }
                }





            }


        };



</script>

<style scoped>

@import "../style/bkmsmall.css";


     .cell {
        text-align: center;
    } 

    .oddsstatic:after {

         content:"\00a0\00a0\00a0"; 
        

        font-size: small;

    }
    .oddupdated {
        animation: fade 5s forwards;
        border-top: solid 3px;
        border-top-color: yellow;
        -webkit-animation: colorchange 5s forwards; /* Chrome and Safari */
    }

    @keyframes colorchange {

        from {
            border-top-color: yellow;
        }

        to {
            border-top-color: white;
        }
    }

    @-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */ {
        from {
            border-top-color: yellow;
        }

        to {
            border-top-color: white;
        }
    }
</style>


