<template>

    <div class="grid-x align-center" >
    
        <div class="cell small-3"><div style="text-align: center"><img style="height:22px;width:22px" src="/assets/images/18plus.png" alt="18+" title="You must be 18+ (or 21 depending on your countries laws) to enter this site"/></div></div>
        <div class="cell small-6 "><div style="text-align: center"><a  href="https://www.begambleaware.org" target="_blank"><img style="width:106px;height:14px;margin-top:-5px" src="/assets/images/GambleAware Main.png" alt="BeGambleaware"/></a></div></div> 
        <div class="cell small-3 qsmallfont" v-show="!tandcs"><div style="text-align: center;margin-top:2px">t&cs</div></div>
    </div>
</template>

<script>
   export default {
        name: "gambleaware", 
        data: function data() {
                return {
         
                }
         },
        components: {
          
        },

     
        props: {
           tandcs: {type: Boolean, default: true}


        }
    }

</script>