    <template>

    <div class="cell large-10">

        <div style="height:4px; background-color: #E6E7E9;"></div>

        <template>
            
            <div v-if="$mq != 'sm'"> 
          
                <div class="grid-x text-center">
                    <div class="cell large-8 medium-12"><adcarousel v-if="$store.state.countryname!=''"></adcarousel></div>
                    <div class="cell large-4 show-for-large"><horsesmouth param1="brief"></horsesmouth></div>
                </div>
            </div>
           <div v-else>
       
                <div class="grid-x text-center">
                   <div class="cell small-12" style="background-color: white;"><adcarouselsmall v-if="$store.state.countryname!=''"></adcarouselsmall></div> 
                </div>
            </div> 
        </template>

        <template>

            <div class="grid-x text-center">
                    <div class="cell" >
                        <menuitems  :templatetype="2"></menuitems>
                    </div>

                   <div class="cell large-8 medium-12">
                        <div style="margin:8px">
                            <tabs @changed="changeTableView">
                                <div v-for="(row,i) in $store.state.oats" :key="i">

                                    <tab :title=row.D img="" :id=row.id :tabtype="$tabtype.textonly" :requirelogin="row.requirelogin" :description="row.Explanation" v-if="row.showmain" :isactive="row.id==$store.state.selectedoat">

                                        <template v-if="row.id==$store.state.selectedoat"> 

                                            <dataviews @dvchanged="dvchange" :spname="$store.state.oats[$store.state.selectedoat].param1" :nodays=14 :sportid="sportkeyid"></dataviews>

                                        </template>

                                    </tab>
                                </div>
                            </tabs>
                        </div>
                        
                    </div>
                    
                        <div class="cell large-4"  v-if="$mq === 'lg'" style="margin-top:-20px">

                            <template v-if="dvloaded==true" >
                    
                                <graph searchtype="dateonly"  graphtype="Line"></graph>

                            </template>
                            
                           
                            <div style="font-weight:bold;padding-left:20px;padding-right:20px; font-size:medium;height:30px;padding-top:5px">What We Do...</div> 

                            <div style="font-size:13px;font-weight: 600;padding-top:25px;padding:4%">

                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'graph' }"  style="color: black" >
                                        <div style="color:white;"><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/bet-whi.png')" alt="" id="img0"></div>
                                        <div style="margin-top:10px;margin-bottom:10px" id="title0">{{ langConv('We publish our bets and strategies for you to view') }}</div>
                                    </router-link>
                                </div>
                                
                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'oddsanalysis' }"  style="color: black" >
                                         <div><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/analytics-whi.png')" alt="" ></div>
                                         <div style="margin-top:10px;margin-bottom:10px" id="title1">{{ langConv('We analyse the odds to help with different betting strategies') }}</div>
                                    </router-link>
                                </div>
                               
                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'bookmakercomparison' }"  style="color: black" >
                                        <div><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/bookmaker-whi.png')" alt="" ></div>
                                        <div style="margin-top:10px;margin-bottom:10px" >{{ langConv('We compare bookmakers to highlight their pros and cons') }}</div>
                                    </router-link>
                                </div>
                                
                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'fullpagehorsesmouth' }"  style="color: black" >
                                        <div style="color:white;"><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/horses-mouth-whi.png')" alt="" id="img0"></div>
                                        <div style="margin-top:10px;margin-bottom:10px" id="title0">{{ langConv('We update you with the latest bookmkaer promotions and news') }}</div>
                                    </router-link>
                                </div>
                                
                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'knowledgebase' }"  style="color: black" >
                                         <div><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/knowledge-whi.png')" alt="" ></div>
                                         <div style="margin-top:10px;margin-bottom:10px" id="title1">{{ langConv('We share what we know about all things gambling') }}</div>
                                    </router-link>
                                </div>
                               
                                <div class="qhighlight" style="border-radius:5px;padding:8px;margin:10px">
                                    <router-link :to="{ name: 'hutchdutchcalc' }"  style="color: black" >
                                        <div><img class="qlargeiconsize qbordericon" :src="require('/public/assets/icons/betcalc.png')" alt="" ></div>
                                        <div style="margin-top:10px;margin-bottom:10px" >{{ langConv('Use our Bet Calculator to access and store your bets') }}</div>
                                    </router-link>
                                </div>




                            </div>

                        </div>

            </div>    
                    
           
        </template> 

    </div>
</template>

 <script>
const adcarousel = () => import('../components/adverts/adcarousel.vue');
import dataviews from  '../components/components/dataviews.vue';
const horsesmouth = () => import('../components/features/horsesmouth.vue');
const graph = () => import('../components/components/graph.vue');
import tab from '../components/modified3rdpartycomponents/tab.vue'
import tabs from '../components/modified3rdpartycomponents/tabs.vue'
import menuitems from '../components/components/menuitems';
const adcarouselsmall = () =>  import('../components/adverts/adcarouselsmall.vue');


    export default {
     data: function data() {
                return {
                sportkeyid: 0,
                oatloaded: false,
                dvloaded: false
                }
    },
    components: {

        adcarousel,
        dataviews,
        horsesmouth,
        graph,
        tab,
        tabs,
        menuitems,
        adcarouselsmall
  
    },

    mounted: function mounted() {

           // reset to now and next
     
    
             this.$store.commit('setOat', 0);
    },

    methods: {

        // appendFile:function appendFile(url){

        
        //         let file = document.createElement('link');
        //         file.rel = 'stylesheet';
        //         file.href = url;
        //         document.head.appendChild(file)
        // },


            changeTableView: function changeTableView(newVal) {

                      //dataview loaded - so graph loads after dataview (as things above)
                      this.dvloaded = false

                      let filteredrows = this.$store.state.oats.filter(row => row.showmain === true);

                      this.$store.commit("setOat",filteredrows[newVal].id);

                },

               refreshTableView: function refreshTableView(newVal) {
                    this.sportkeyid = newVal;
                },



            dvchange: function dvchange(newVal) {

                this.dvloaded=true

            },
             }
    }



</script>

