<template>
<header>   
<nav>
<div class="grid-x">
    
   <div class="cell xlarge-0 xxlarge-2 show-for-xlarge qbordercolour">

   </div>
   <div class="cell large-12 xlarge-10 xxlarge-8">
   
        <nav  class="top-bar qbordercolour" id="a">
            <div  class="top-bar-left">
                <ul  class="menu qbordercolour">
                    <li  class="dropdown" v-if="$mq === 'sm' || $mq === 'md'">
                        <div @mouseover="enableMenu('menudown')"  style="padding:0px 0px 0px 8px">
                            <img class="qwhiteicon" style="height:24px;width:24px" :src="require('/public/assets/images/menu-26.png')"  alt="" /><br  />
                            <span style="margin-left:2px;position:absolute" class="qxsupersmallfont qwhitetext">{{langConv('Menu')}}</span>
                        </div>
                        <features id="menudown" type="down" style="margin-top:15px"></features>
                    </li> 
                    <li v-if="$mq === 'sm'">
                        <router-link :to="{ name: 'home' }"><img width="160px" height="23px" :src="require('/public/assets/images/OddsGalorelogo.webp')" alt="Oddsgalore Logo"/></router-link>
                    </li>
                    <li v-else>
                        <router-link :to="{ name: 'home' }"><img width="230px" height="31px" :src="require('/public/assets/images/OddsGalorelogo.webp')" alt="Oddsgalore Logo"/></router-link>
                    </li>
                    <li class="qsmallfont [radius round]" style="margin-left:-40px;color: white;background-color: green;height:16px;padding: 0px 3px 3px 3px" >
                        Beta
                    </li>
                    

                </ul> 
            </div>

            <div class="top-bar-right"  >
                <ul class="menu  qbordercolour">
                    <li class="dropdown">
                        <a class="qmediumboldfont show-for-medium" v-html="langConv('Search')"></a>
                        <a class="qmediumboldfont show-for-small hide-for-medium"><img class="qwhiteicon" :src="require('/public/assets/images/search-26.png')" style="width:20px;height:20px" alt="" /></a>

                        <ul class="dropdown-content-center qbordercolour">
                            <li class="qsmallfont" style="width:200px">
                                <autocomplete :search="searchDropdownData" :get-result-value="getResultValue" :placeholder="langConv('find teams, competitions and more')" @submit="handleSubmit"></autocomplete>
                            </li>
                        </ul>

                    </li>

                    <li @mouseover="enableMenu('settingsmenu')" @mouseleave="disableMenu('settingsmenu')" class="dropdown">
                        <div>
                            <a class="qmediumboldfont show-for-medium " v-html="langConv('Settings')"></a>
                            <a class="qmediumboldfont show-for-small hide-for-medium" ><img class="qwhiteicon" :src="require('/public/assets/images/settings-26.png')" style="width:20px;height:20px" alt=""/></a>
                        </div>
                        <ul  id="settingsmenu" class="dropdown-content-center qbordercolour qsmallfontplus1">
                         
                          <li>
                            <div class="switch medium" style="font-weight:400">  
                            
                                <div class="grid-x">
                                    <div class="cell small-5 small-offset-1 qbordercolour qsmallfontplus1" style="">Tooltips</div>
                                    <div class="cell small-6">
                                    <input  class="switch-input" id="tipsSwitch" type="checkbox" name="hintsSwitch" v-on:click="setHints">
                                        <label class="switch-paddle" for="tipsSwitch">
                                        <span class="show-for-sr">{{ langConv("Enable Hints") }}</span>
                                        <span class="switch-active" aria-hidden="true">{{ langConv("Yes") }}</span>
                                        <span class="switch-inactive" aria-hidden="true">{{ langConv("No") }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                          </li>


                            <li>
                                <ul @click="disableMenu('settingsmenu')" class="horizontal menu qbordercolour" style="font-size: smaller">
                                    <li :class="{ 'is-active': this.isdecimal }" class="qbordercolour">
                                        <a v-on:click="changeOdds" id="1">3.0</a>
                                    </li>
                                    <li :class="{ 'is-active': this.isfractional }">
                                        <a v-on:click="changeOdds" id="2">2/1</a>
                                    </li>
                                    <li :class="{ 'is-active': this.isamerican }">
                                        <a v-on:click="changeOdds" id="3">+300</a>
                                    </li>
                                </ul>
                            </li>
                            <!-- <li>
                                <a><span>Help</span></a>
                            </li> -->
                            <li>
                                <router-link @click.native="disableMenu(false,'settingsmenu')" :to="{ name: 'exchangesettings' }" v-html="langConv('Exchange Settings')"></router-link>
                            </li>
                            <li>
                                <router-link @click.native ="disableMenu(false,'settingsmenu')" :to="{ name: 'bookmakersettings' }" v-html="langConv('Select Bookmakers')"></router-link>
                            </li>
                            <li>
                                <router-link @click.native ="disableMenu(false,'settingsmenu')" :to="{ name: 'timezone' }" v-html="langConv('Time Zone')"></router-link>
                            </li>
                            <li>
                                <router-link @click.native ="disableMenu(false,'settingsmenu')" :to="{ name: 'licensesettings' }" v-html="langConv('Bookmaker License Settings')"></router-link>
                            </li>
                            <li>
                                <router-link @click.native="disableMenu(false,'settingsmenu')" :to="{ name: 'feedback' }" v-html="langConv('Contact us')"></router-link>
                            </li>
                       
                        </ul>
                    </li>   

        <!-- logged in?  -->

                <template v-if="$store.state.signedin">
                    <li class="show-for-medium" >
                        <a class="qmediumboldfont" v-on:click="logOut" style="color:white">{{ $store.state.obfuscatedname}}  - {{langConv("Log Out")}}</a>
                    </li>
                    <li class="show-for-small hide-for-medium">
                        <a class="qsupersmallfont text-center" v-on:click="logOut" style="color:white"><img class="qwhiteicon" :src="require('/public/assets/images/unlock-26.png')" style="width:20px;height:20px" alt=""/></a>
                    </li>
                </template>
                <template v-else>
                    <li class="show-for-medium ">
                        <router-link :to="{ name: 'login' }" class="qmediumboldfont"  >{{ langConv("Login") }}</router-link>
                    </li>
                    <li class="show-for-small hide-for-medium">
                        <router-link :to="{ name: 'login' }">
                        <img class="qwhiteicon" :src="require('/public/assets/images/lock-26.png')" style="width:20px;height:20px" alt=""/>
                    </router-link>
                    </li>
                </template>
            </ul>
        </div>
    
    </nav>

   </div>
   <div class="cell xlarge-2 xxlarge-2 show-for-xlarge qbordercolour">
   
</div>
   
</div>
</nav>
</header>
</template>

<script>
//autocomplete box

//import Vue from 'vue';
import axios from "axios";
import features from '../../components/components/features.vue'
import autocomplete from "@trevoreyre/autocomplete-vue";
//import vueJsonExcelUmd from "vue-json-excel";

export default {
  name: "navbar",
  data: function () {
    return {
      isdecimal: true,
      isfractional: false,
      isamerican: false,
      sports: this.sportswithodds,
      country: this.$store.state.countryname,
    };
  },

  props: {
    cn: String,
    sportswithodds: Array,
  },

  mounted() {
    this.setOddsType();
  },
  components: {
    autocomplete,
    features
  },

  computed: {
            hints () {
                return this.$store.state.showhints;
            }

        },
  methods: {

    enableMenu: function (val) {
        document.getElementById(val).style.display = "block";
    },

    disableMenu: function(val) {
        document.getElementById(val).style.display = "none";
    },




    setHints: function(val) {
       

    if (this.$store.state.showhints == true) {
       this.$store.commit("setShowHints", false); 
    } else {
       this.$store.commit("setShowHints", true); 
    }
    this.$forceUpdate();

},


    resetSite: function () {
        //reset IP
        this.genericYesNo(
        `Reset site settings to defaults. Continue?`
      ).then((response) => {
        if (response == true) {
        
            this.$store.commit("setIPAddress", "");
            this.setIPDependantDefaults();
        }
      })
    },

 
    

    //show Modal window
    showModal: function (val) {
      this.$modal.show(val + "modal");
    },

    changeOdds: function (event) {
      //change to type
      let newtype = parseInt(event.target.id);

      //change cookie
      this.$store.commit("setCurrentot", newtype);

      this.setOddsType(newtype);

      this.saveuser();
    },

    setOddsType: function (ot) {
      if (ot == null) {
        ot = this.$store.state.currentot;
      }

      //change active switch box
      switch (ot) {
        case this.$oddstype.decimal:
          this.isdecimal = true;
          this.isfractional = false;
          this.isamerican = false;
          break;
        case this.$oddstype.fractional:
          this.isdecimal = false;
          this.isfractional = true;
          this.isamerican = false;
          break;
        case this.$oddstype.american:
          this.isdecimal = false;
          this.isfractional = false;
          this.isamerican = true;
          break;
        default:
          this.isdecimal = false;
          this.isfractional = false;
          this.isamerican = false;
      }
    },

    //autocomplete events

    handleSubmit(result) {
     // window.location.href = "/Search/" + result.event;
        this.$router.push("/Search/" + result.event);
    },

    //get full data for search results
    searchDropdownData(input) {
      if (input.length < 3) {
        return [];
      }

      var URL = process.env.VUE_APP_WEBAPI + "FeatureTables.svc/SearchItems";

      return new Promise((resolve) => {
        axios
          .get(URL, {
            params: {
              desc: input, 
              noitems: 50, //parseInt(this.noitems), // does nothing at the moment 18/01/2019
              wildcard: true,
              sportid: -1 
            },
          })
          .then(
            (response) => {
              //any BO elements
              let data = JSON.parse(response.data)[0];

              if (Object.keys(data).length > 1) {
                data = data.BO;
              } else {
                data = [];
              }
              return resolve(data);
            },

            (error) => {
              console.log(error);
            }
          );
      });
    },

    getResultValue(result) {
      return result.event;
    },
  },
};

</script>


<style scoped>




.switch-paddle::after {
    width: 1rem;
    height: 1rem;
    background: #feca05;
}

.switch-paddle {

    width: 3.5rem;
    height: 1.7rem;
    font-size: smaller;
    font-weight: normal;
    background:#0d0638;
    border: 1px solid white;
}

input:checked ~ .switch-paddle {
    background: #0d0638;
}

input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: gray;}






a {
    color: white;  
}

</style>