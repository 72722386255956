
                            <template>
                                <div>
                                    <template v-if="promodetailloaded">
                                        <div v-for="(row,i) in advertpop" :key="i">
                                        <div>
                                            
                                                <div class="grid-x " :style="{backgroundColor: row.BackColour}" style="padding:5px">
                                                
                                                        <div class="cell auto qsmallfontplus1" >
                                                            <span class="qsmallfont qwhitetext qallplayers">{{langConv('All Players')}}</span><a :href="row.BanURL" target="_blank">
                                                            <span :class="'m' + row.BkmName"></span></a> <br/>
                                                            <span v-if="row.BackColour.toLowerCase() == 'white'"><a style="color: black;" :href="row.BanURL" target="_blank" >{{ row.BanDesc }}</a></span>
                                                            <span v-else><a class="qwhitetext" :href="row.BanURL" target="_blank" >{{ row.BanDesc }}</a></span>
                                                        
                                                        </div>
                                                          
                                                        <div class="cell shrink show-for-medium" style="padding:12px">
                                                            <actionbutton :href="row.BanURL" :bookmaker="row.BkmName" buttontext="Get Offer" ></actionbutton>
                                                        </div>
                                                        <div class="cell small-2 show-for-small-only" style="padding:12px">
                                                           
                                                            <actionbutton :href="row.BanURL" :bookmaker="row.BkmName" buttontext="Go"></actionbutton>
                                                        </div>

                                                </div>
                                        
                                        </div>
                                        </div>
                                    </template>
                               </div>
                            </template>

<script>
import axios from 'axios'
import actionbutton from '/src/components/components/actionbutton.vue'

    export default {
        name: "bannerodds",
        data: function () {
            return {
                advertpop: [],
                promodetailloaded: false,
                competitionID: this.CompID
            }
        },
        mounted() {

            this.advertPop();
        },
        props: {
            CompID: Number
        },
        components: {
            actionbutton
        },
        methods: {
            advertPop: function () {

                this.promodetailloaded = false;

                axios.get(process.env.VUE_APP_WEBAPI  + "AdvertService.svc/AdvertPop", {

                    params: {

                        compID: this.competitionID,
                        bkmID: 0,
                        offertypeID: 3,
                        eventid: 0,
                        country:  this.$store.state.countryname
                        
                    }

                }).then(response => { 
             

                    if (response.data!=null && response.data !='') {
                    this.advertpop = JSON.parse(response.data); 
                    this.promodetailloaded = true; 
                }
                }).catch(error => {
                   
                    console.log(error)
                });

            },

        }
    };

 </script>

<!-- <style>

      .dropdown {
        position: relative;
        display: inline-block;
    }

        .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

        .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

    .dropdown:hover .dropdown-content {
        display: block;
    }  

</style> -->
